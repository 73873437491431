.bookIt {
    margin-right: 10px;
    padding-top: 2px;
}

.bookIt > div > div > div > span > span {
    color: #495057 !important;
 }

 /* TODO: Şu an için mecburen DFDS UI component e dokundum. Lakin Multiple select gelince değiştirilmesi gerekir */
.searchText > input {
    height: calc(1.5em + .75rem) !important;
}

.divTable{
    display: table;
    width: 100%;
}
.divTableRow {
    display: table-row;
}
.divTableHeading {
    background-color: #EEE;
    display: table-header-group;
}
.divTableCellLeft, .divTableHead {
    display: table-cell;
    padding: 3px 10px;
    width: 150px;
    font-weight: bold;
}
.divTableCellRight, .divTableHead {
    display: table-cell;
}
.divTableHeading {
    background-color: #EEE;
    display: table-header-group;
    font-weight: bold;
}
.divTableFoot {
    background-color: #EEE;
    display: table-footer-group;
    font-weight: bold;
}
.divTableBody {
    display: table-row-group;
}
