.thumbnails {
    width: 100%;
    overflow-x: scroll;
    white-space: nowrap;
}

.thumbnails img {
    width: 200px;
    display: inline-block;
    border: 1px solid #CCC;
    margin: 2px;
    cursor: pointer;
}

.splitCard {
    margin-top: 5px;
}

.splitCardHeader {
    cursor: pointer;
}
.preview {
    width: 600px !important;
}

img {
    -webkit-transition: 0.2s ease-in-out;
    -moz-transition: 0.2s ease-in-out;
    -o-transition: 0.2s ease-in-out;
    transition: 0.2s ease-in-out;
}

.inputFile {
    cursor: pointer;
    width: 0.1px;
	height: 0.1px;
	opacity: 0;
	overflow: hidden;
	position: absolute;
	z-index: -1;
}

.inputfile + label {
    cursor: pointer;
    font-size: 1.25em;
    font-weight: 700;
    color: white;
    background-color: black;
    display: inline-block;
}

.inputfile:focus + label,
.inputfile + label:hover {
    background-color: red;
}

.inputfile + label {
	cursor: pointer; /* "hand" cursor */
}

.inputfile:focus + label {
	outline: 1px dotted #000;
	outline: -webkit-focus-ring-color auto 5px;
}
.inputfile + label * {
	pointer-events: none;
}

.inputFileWrapper {
    cursor: pointer;
    display: inline-flex;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    position: relative;
    height: 44px;
    padding: 0 20px;
    border: 2px solid transparent;
    border-radius: 2px;
    box-sizing: border-box;
    text-decoration: none;
    text-align: center;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    vertical-align: middle;
    font-weight: 700;
    overflow: visible;
    text-transform: uppercase;
    max-width: 100%;
    font-size: 1rem;
    white-space: nowrap;
    text-overflow: ellipsis;
    -webkit-transition: background-color 120ms ease,color 120ms ease;
    transition: background-color 120ms ease,color 120ms ease;
    color: #fff;
    background: #ed8b00;
    margin: 0px 5px !important;
}