.additional-gate-date-picker {
  width: 100%;
  overflow: visible;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  cursor: pointer;
  border: 1px solid rgba(77, 78, 76, 0.7);
  background-color: #ffffff;
  font-size: 16px;
  position: relative;
  height: 50px;
  padding: 0 16px;
}
.date-picker-label {
  margin-bottom: 0px !important;
}
