:root {
  --label-color: #212529;
}

header {
  overflow: hidden;
}

header .title {
  float: left;
}

header .title * {
  float: left;
}

header .functions {
  float: right;
}

header .functions * {
  float: right;
}

/* FORMS */
form {
  margin: 12px;
}

label.label {
  font-weight: bold;
  margin-bottom: 0px;
  color: var(--label-color);
  border: none;
}

input,
select {
  width: 100%;
  padding: 6px;
  margin-top: 2px;
  margin-bottom: 0px;
}

input ~ small,
select ~ small {
  display: inline-block;
  margin-bottom: 10px;
}

input:focus,
select:focus {
  border: 2px solid #49a2df;
}

input:read-only {
  color: gray;
  border: 1px solid #ddd;
  background-color: #eee;
}

select {
  height: 38px;
}

input[type="checkbox"] {
  width: 20px !important;
  height: 30px;
}

input[type="submit"] {
  width: 50%;
  background: #ed8800;
  color: #fff;
  border: 2px solid transparent;
  border-radius: 2px;
  cursor: pointer;
  user-select: none;
  text-transform: uppercase;
}

input[type="button"] {
  color: #fff;
  border: 2px solid transparent;
  border-radius: 2px;
  cursor: pointer;
  user-select: none;
  text-transform: uppercase;
}

input.secondary {
  background: #49a2df;
}

input.danger {
  background: rgb(190, 30, 45);
}
/* end FORMS */

.associateRow {
  margin-bottom: 6px;
}

.associateRow:hover {
  background-color: #ddd;
}

.associateRow:nth-child(2n) {
  background: #ecf0f5;
}

/** ICONS **/
.iconButton {
  font-size: 2rem;
  cursor: pointer;
}

.iconButton:hover {
  color: #555;
}

.iconButton:active {
  color: #999;
}
/** end ICONS*/

/** LABELS */
.label {
  display: inline-block;
  color: white;
  border: 1px solid;
  padding: 4px 8px;
  margin: 2px 6px 20px 0px;
  user-select: none;
}
/** end LABELS */

.blue {
  background-color: var(--blue);
  border-color: var(--blue);
}
.green {
  background-color: var(--green);
  border-color: var(--green);
}
.indigo {
  background-color: var(--indigo);
  border-color: var(--indigo);
}
.purple {
  background-color: var(--purple);
  border-color: var(--purple);
}
.pink {
  background-color: var(--pink);
  border-color: var(--pink);
}
.red {
  background-color: var(--red);
  border-color: var(--red);
}
.orange {
  background-color: var(--orange);
  border-color: var(--orange);
}
.variousValues {
  color: var(--warning);
  border-color: var(--warning);
}
.gray {
  background-color: var(--gray);
  border-color: var(--gray);
}

.fixEnd{
  display: flex;
  align-items: flex-end;
}