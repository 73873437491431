:root {
  --label-color: #212529;
}
/* HEADER */
header {
  overflow: hidden;
}

header .title {
  float: left;
}

header .title * {
  float: left;
}

header .functions {
  float: right;
}

header .functions * {
  float: right;
}
/* end HEADER */

/* FORMS */
form {
  margin: 12px;
}

label.label {
  font-weight: bold;
  margin-bottom: 0px;
  color: var(--label-color);
}

input,
select {
  width: 100%;
  padding: 6px;
  margin-top: 2px;
  margin-bottom: 0px;
}

input:focus,
select:focus {
  border: 2px solid #49a2df;
}

input[type="checkbox"] {
  width: 20px !important;
  height: 30px;
}

form input[type="button"] {
  color: #fff;
  border: 2px solid transparent;
  border-radius: 2px;
  cursor: pointer;
  user-select: none;
  text-transform: uppercase;
}

input[type="button"].secondary {
  background-color: #49a2df;
}

input[type="button"].secondary:hover {
  background-color: #1874bc;
}

input[type="button"].danger {
  background-color: var(--danger);
}

form input[type="submit"] {
  width: 50%;
  background-color: var(--orange);
  color: #fff;
  border: 2px solid transparent;
  border-radius: 2px;
  cursor: pointer;
  user-select: none;
  text-transform: uppercase;
}

input[type="submit"]:hover {
  background-color: #e16900;
}

select {
  height: 38px;
}

/* constraint validation */
input ~ small,
select ~ small {
  display: inline-block;
  margin-bottom: 10px;
}

/*
will be developed later, small may or may not be used for both validation and other messages. or only for other than validation messages
input:invalid ~ small,
select:invalid ~ small {
  visibility: visible;
}
*/

input:read-only {
  color: gray;
  border: 1px solid #ddd;
  background-color: #eee;
}
/* END constraint validation */

/* end FORMS */

/* ROWS */
.associateRow {
  margin-bottom: 6px;
}

.associateRow:nth-child(2n) {
  background: #ecf0f5;
}

.associateRow:hover {
  background-color: #ddd;
}
/* end ROWS */

/** ICONS **/
.iconButton {
  font-size: 2rem;
  cursor: pointer;
}

.iconButton:hover {
  color: #555;
}

.iconButton:active {
  color: #999;
}
/** end ICONS*/

/** LABELS */
.label {
  display: inline-block;
  color: white;
  padding: 6px;
  margin: 2px 6px 20px 0px;
  user-select: none;
}
/** end LABELS */

/* STATUSES */
.success {
  background-color: #4caf50;
}
.info {
  background-color: #49a2df;
}
.warning {
  background-color: #ff9800;
}
.danger {
  background-color: #be1e2d;
}
.other {
  background-color: #e7e7e7;
  color: black;
}
/* end STATUSES */
