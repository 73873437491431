:root {
  --label-color: #212529;
}

header.header {
  overflow: hidden;
}

header .title {
  float: left;
}

header .title * {
  float: left;
}

header .functions {
  float: right;
}

header .functions * {
  float: right;
}

/** FORMS **/
label.label {
  font-weight: bold;
  margin-top: 10px;
  margin-bottom: 0px;
  color: var(--label-color);
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  white-space: nowrap;
}
/** end FORM */

/** ICONS **/
.iconButton {
  font-size: 2rem;
  cursor: pointer;
}

.iconButton:hover {
  color: #666;
  background-color: #ddd;
}

.iconButton:active {
  color: #999;
}

.iconButtonActive {
  color: orange;
}
/** end ICONS*/

.functionControls {
  clear: both;
  margin-bottom: 0.5rem;
  padding: 1.5rem 1.5rem;
}

.summarySection {
  clear: both;
  margin: 0.5rem auto;
  padding: 0.75rem 1.5rem;
}

/** POPOVER */
.popover {
  position: absolute;
  height: 600px;
  overflow: auto;
  margin: -2rem;
  margin-left: 1.2rem;
  padding: 1.2rem;
  opacity: 0.9;
  border: 1px solid gray;
  box-shadow: gray 1px 1px 2px;
  z-index: 10;
}

.popover div[draggable] {
  cursor: move;
  display: flex;
  justify-content: flex-start;
  padding: 0.5rem;
}

.popover div[draggable]:hover {
  background-color: #ddd;
}

.popover div[draggable]:active {
  background-color: #bbb;
  cursor: grabbing;
}

.popover div[draggable] input[type='checkbox'] {
  margin-right: 0.5rem;
  align-self: flex-start;
}

/** end POPOVER */

/** TABLE */
caption.caption {
  padding: 10px;
  caption-side: bottom;
}

table.table {
  border-collapse: collapse;
  width: 100%;
}

th.th,
td.td {
  border-bottom: 1px solid #ddd;
  padding: 0 8px 0;
  vertical-align: middle;
}

tr.tr:nth-child(even) {
  background-color: #f2f2f2;
}

tr.tr:hover {
  background-color: #ddd;
}

tr.tr:active {
  background-color: #bbb;
}

th.th {
  padding-top: 0px;
  padding-bottom: 0px;
  text-align: left;
  color: white;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  white-space: nowrap;
}

th.th input,
td.td input {
  margin-bottom: 0px;
}

.tableContainer {
  overflow-x: auto;
}

/** wrap & text overflow */
.nowrap {
  white-space: nowrap;
}

.clip {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: clip;
}

.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
/** END text overflow */
/** end TABLE **/

input[type='button'].primary,
input[type='submit'].primary {
  background-color: var(--orange);
}

input[type='button'].primary:hover,
input[type='submit'].primary:hover {
  background-color: #e16900;
}

input[type='button'].secondary,
input[type='submit'].secondary {
  background-color: #49a2df;
}

input[type='button'].secondary:hover,
input[type='submit'].secondary:hover {
  background-color: #1874bc;
}

input[type='button'].outlined,
input[type='reset'].outlined {
  border: 2px solid #49a2df;
  background-color: transparent;
  color: #49a2df;
}

input[type='button'].outlined:hover,
input[type='reset'].outlined:hover {
  border: 2px solid #1874bc;
  background-color: transparent;
  color: #1874bc;
}

input[type='button'].textual,
input[type='reset'].textual {
  border: 2px solid transparent;
  background-color: transparent;
  color: #49a2df;
  font-weight: bold;
}

input[type='button'].textual:hover,
input[type='reset'].textual:hover {
  border: 2px solid transparent;
  background-color: transparent;
  color: #1874bc;
  font-weight: bold;
}

input[type='button'].disabled {
  background-color: rgba(77, 78, 76, 0.3);
}
