.thuList-card-header,
.thuList-card-body {
  padding: 0.75rem 1.25rem 0.75rem 1.25rem;
}

.thuFunctions > * {
  margin-bottom: 4px;
}

header {
  overflow: hidden;
}

header .title {
  float: left;
}

header .title * {
  float: left;
}

header .functions {
  float: right;
}

header .functions * {
  float: right;
}

/** ICONS **/
.iconButton {
  font-size: 2rem;
  cursor: pointer;
}

.iconButton:hover {
  color: #555;
}

.iconButton:active {
  color: #999;
}

.iconButtonActive {
  color: orange;
}
/** end ICONS*/
