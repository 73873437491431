.shipments-card-header, .shipments-card-body{
    padding: 0.75rem 1.25rem 0.75rem 1.25rem;
}

.shipments-count{
    margin-left: 8px; 
    padding-top: 4px;
}

.shipments-checkbox{
    margin-bottom : 0px;
}

.isRush{
    color: #be1e2d;
    font-weight: bold;
}