.background-text {
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: .7; 
    display: flex;
    justify-content: center;
    align-items: center;
    top:0px;
    left: 0px;
}

.background-text-content {
    color: white;
    text-align: center;
    font-size: 50px;
    display: block;
    letter-spacing: 15px;
    font-weight: bold;
    font-family: 'DFDS-Bold';
    flex: 0 0 120px;
}