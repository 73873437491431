.navbar {
  background-color: #002b45;
}

.navbar .nav > li > a {
  color: #ffffff;
}

.navbar .nav > li > a:hover,
.navbar .nav > li > a:active,
.navbar .nav > li > a:focus,
.navbar .nav .open > a,
.navbar .nav .open > a:hover,
.navbar .nav .open > a:focus,
.navbar .nav > .active > a {
  background: rgba(0, 0, 0, 0.1);
  color: #f6f6f6;
}

.navbar .sidebar-toggle {
  color: #ffffff;
}

.navbar .sidebar-toggle:hover {
  color: #f6f6f6;
  background: rgba(0, 0, 0, 0.1);
}

.navbar .sidebar-toggle {
  color: #fff;
}

.navbar .sidebar-toggle:hover {
  background-color: #002b45;
}

.navbar-more-menu {
  display: none !important;
}

.navbar-more-menu + div {
  display: none !important;
}

@media (max-width: 767px) {
  .navbar .dropdown-menu li.divider {
    background-color: rgba(255, 255, 255, 0.1);
  }
  .navbar .dropdown-menu li a {
    color: #fff;
  }
  .navbar .dropdown-menu li a:hover {
    background: #002b45;
  }
  .navbar-more-menu {
    display: initial !important;
  }
  .navbar-more-menu + div {
    display: initial !important;
    right: -12%;
  }
  .navbar-more-menu #navbar-more-menu-icon {
    margin-top: 1.2rem;
    color: #49a2df;
  }
  img.logo_new {
    margin-right: 12px !important;
  }
}

.logo {
  background-color: #002b45;
  color: #ffffff;
  border-bottom: 0 solid transparent;
}

li.user-header {
  background-color: #002b45;
}

.content-header {
  background: transparent;
}

.logo {
  -webkit-transition: width 0.3s ease-in-out;
  -o-transition: width 0.3s ease-in-out;
  transition: width 0.3s ease-in-out;
  display: block;
  float: left;
  height: 70px;
  font-size: 20px;
  line-height: 50px;
  text-align: center;
  width: 230px;
  font-family: "Dfds", "Helvetica Neue", Helvetica, Arial, sans-serif;
  padding: 0 15px;
  font-weight: 300;
  overflow: hidden;
}

.logo-small-img {
  margin-top: 10px;
  width: 35px;
}

.logo-lg-img {
  margin-top: 6px;
  width: 175px;
}

.navbar-static-top {
  height: 70px;
  padding: 0px;
}

.navbar-nav {
  flex-direction: unset;
}

.navbar-nav > li {
  padding-right: 5px;
}

.wrapper,
.main-sidebar,
.left-side {
  background-color: #002b45;
}

a {
  cursor: pointer;
}

.my-user-info {
  margin-right: 50px;
}

.dropdown-menu {
  left: auto;
  right: 0;
}
