.quantity {
  font-weight: bolder;
  text-align: center;
}

.warning {
  font-weight: bolder;
  text-align: center;
  color: orange;
}
