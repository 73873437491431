.scanner {
  text-align: center;
}

div#video-container {
  position: relative;
  height: 200px;
}

video {
  width: 40%;
  margin: 0 auto;
}

@media screen and (max-width: 992px) {
  video {
    width: 100%;
  }
}
