header.header {
  overflow: hidden;
}

header .title {
  float: left;
}

header .title * {
  float: left;
}

header .functions {
  float: right;
}

header .functions * {
  float: right;
}

/** ICONS **/
.iconButton {
  font-size: 2rem;
  cursor: pointer;
}

.iconButton:hover {
  color: #666;
  background-color: #ddd;
}

.iconButton:active {
  color: #999;
}

.iconButtonActive {
  color: orange;
}
/** end ICONS*/

/** FILTERS */

/** end FILTERS */

/** FAB: floating action button */
.fab {
  display: inline-block;
  position: fixed;
  bottom: 40px;
  right: 30px;
  z-index: 99;
  font-size: 18px;
  width: 80px;
  height: 80px;
  border: none;
  border-radius: 50% !important;
  outline: none;
  background-color: dodgerblue;
  color: white;
  cursor: pointer;
  padding: 15px;
  border-radius: 4px;
}
/** end FAB */

.functionSection {
  margin: 6px 0px;
  width: 100%;
}

/** TABLE */
caption.caption {
  padding: 10px;
  caption-side: bottom;
}

table.table {
  border-collapse: collapse;
  width: 100%;
}

th.th,
td.td {
  border-bottom: 1px solid #ddd;
  padding: 0 8px 0;
  vertical-align: middle;
}

tr.tr:nth-child(even) {
  background-color: #f2f2f2;
}

tr.tr:hover {
  background-color: #ddd;
}

tr.tr:active {
  background-color: #bbb;
}

th.th {
  padding-top: 0px;
  padding-bottom: 0px;
  text-align: left;
  background-color: #4caf50;
  color: white;
}

th.th input,
td.td input {
  margin-bottom: 0px;
}

.tableContainer {
  overflow-x: auto;
}
/** end TABLE **/
