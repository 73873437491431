.functionControls {
    clear: both;
    margin-bottom: 0.5rem;
    padding: 1.5rem 1.5rem;
  }
  
  /** TODO: obsolete this with functionControls */
  .functionSection {
    margin: 0px;
    width: 100%;
  }